<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      order="2"
      order-lg="1"
    >
      <p class="font-weight-bold">
        {{ $t('payment.billing-data.title') }}
      </p>
      <CustomerForm
        ref="customerForm"
        v-model="customer"
        hide-submit
        show-is-company
        show-country
        :payment-methods="paymentMethods"
        :show-coupon-field="false"
        :has-payment-provider="false"
        :is-yearly="localIsYearly"
        @update-preview="debouncedUpdated"
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
      order="1"
      order-lg="2"
    >
      <v-card class="pt-4 shadow pa-4 pa-md-8 mx-md-4 card-shadow-invert">
        <div>
          <p class="px-0 font-weight-bold">
            {{ $t('payment.billing-info.title') }}
          </p>
          <v-radio-group
            v-model="localIsYearly"
            row
          >
            <RadioButton
              class="flex-grow-1"
              :value="true"
            >
              <div class="pb-2 font-weight-bold">
                {{ $t('payment.billing-info.charge.label-two') }}
              </div>
              <div>{{ $t('payment.billing-info.description', {percent: 20}) }}</div>
            </RadioButton>
            <RadioButton
              class="flex-grow-1"
              :value="false"
            >
              <div class="pb-2 pb-6 font-weight-bold">
                {{ $t('payment.billing-info.charge.label-one') }}
              </div>
            </RadioButton>
          </v-radio-group>
        </div>
        <p class="px-0 mt-4 font-weight-bold">
          {{ $t('payment.coupon.title') }}
        </p>
        <Coupon
          :coupon-code="localCouponCode"
          :coupon="{isCouponValid: previewOrder?.isCouponValid, ...previewOrder?.coupon}"
          :is-preview-order-loading="isPreviewOrderLoading"
          @apply-coupon="(code) => localCouponCode = code"
        />
        <PreviewOrder
          v-if="previewOrder"
          class="mt-0 mb-5 col-12 pa-0"
          :preview-order="previewOrder"
          :is-preview-order-loading="isPreviewOrderLoading"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import * as Sentry from '@sentry/vue'
import CustomerForm from '@/modules/billwerk/subscribe/CustomerForm.vue'
import RadioButton from '@/components/RadioButton.vue'
import featureMixin from '@/mixins/feature'
import PreviewOrder from '../billwerk/subscribe/PreviewOrder.vue'
import Coupon from '../billwerk/subscribe/Coupon.vue'
import brandingMixin from '@/mixins/branding'
import { billwerkApi } from '../billwerk/lib'
import { debounce } from '@/lib/debounce'
import { getProductPackageQuery } from '@/modules/productPackages/lib/productQuery'

const FALLBACK_PAYMENT_METHOD = 'CreditCard:Reepay'

export default {
  components: { CustomerForm, RadioButton, PreviewOrder, Coupon },
  mixins: [featureMixin, brandingMixin],
  props: {
    customerDefault: {
      type: Object,
      default: () => ({ address: {} })
    },
    productType: {
      type: String,
      required: true
    },
    isYearly: {
      type: Boolean,
      default: false
    },
    currency: {
      type: String,
      required: true
    },
    couponCode: {
      type: String,
      default: ''
    }
  },
  data () {
    const address = this.customerDefault.address || {}
    return {
      productPackageQuery: getProductPackageQuery() || {},
      previousPreviewOrderData: {},
      isLoading: false,
      isBillingInProgress: false,
      localIsYearly: this.isYearly,
      localCurrency: this.currency,
      localCouponCode: this.couponCode,
      previewOrder: null,
      isPreviewOrderLoading: false,
      paymentMethods: [],

      /**
       * This object will be passed as is to SubscriptionJS.
       */
      customer: {
        emailAddress: this.customerDefault.email || '',
        firstName: this.customerDefault.firstName || '',
        lastName: this.customerDefault.lastName || '',
        companyName: this.customerDefault.companyName || '',
        vatId: this.customerDefault.vatId || '',
        paymentMethod: '',
        address: {
          street: address.street || '',
          houseNumber: address.houseNumber || '',
          postalCode: address.postalCode || '',
          city: address.city || '',
          country: address.country || ''
        }
      }
    }
  },
  computed: {
    cart () {
      const { priceLists, packages } = this.$features.feature(this.featureNames.BILLWERK)?.config
      const paymentCycle = this.localIsYearly ? 'yearly' : 'monthly'
      const productPackage = packages.find((productPackage) =>
        this.productType === productPackage.productType &&
        (!productPackage.paymentCycle || productPackage.paymentCycle === paymentCycle)
      )

      if (!productPackage) {
        Sentry.captureMessage('No product package found for product type', {
          extra: { productType: this.productType, paymentCycle, packages, priceLists },
          level: 'error'
        })
        return {
          priceListId: priceLists.default.id,
          planVariantId: '',
          couponCode: this.localCouponCode
        }
      }

      const planVariantId = productPackage.billwerkId
      const priceListId = Object.values(priceLists).find(({ currency }) => currency === this.localCurrency)?.id ?? priceLists.default.id

      return {
        priceListId,
        planVariantId,
        couponCode: this.localCouponCode
      }
    },
    debouncedUpdated () {
      return debounce(this.updated, 2000)
    }
  },
  watch: {
    isYearly (newVal) {
      this.localIsYearly = newVal
    },
    localCouponCode (newVal) {
      this.productPackageQuery.couponCode = newVal
      this.updateProductPackageQuery()
    },
    localIsYearly (newVal) {
      this.productPackageQuery.isYearly = newVal
      this.updateProductPackageQuery()
    },
    currency (newVal) {
      this.localCurrency = newVal
    },
    cart: {
      handler: 'updated',
      deep: true
    },
    customer: {
      handler () {
        this.debouncedUpdated()
      },
      deep: true
    }
  },
  mounted () {
    const { config } = this.$features.feature(this.featureNames.BILLWERK)
    const paymentMethods = config.paymentMethods.filter((method) => {
      // Show Apple Pay only if supported
      return method !== 'ApplePay:Reepay' || window.ApplePaySession
    }) || [FALLBACK_PAYMENT_METHOD]

    this.paymentMethods = paymentMethods
    this.customer.paymentMethod = paymentMethods[0]
    this.updated()
  },
  methods: {
    updateProductPackageQuery () {
      sessionStorage.setItem('productPackageQuery', JSON.stringify(
        this.productPackageQuery
      ))
    },
    validate () {
      return this.$refs.customerForm.validate()
    },
    async fetchPreviewOrder () {
      const previewOrderData = JSON.stringify({ cart: this.cart, customer: this.customer })
      if (this.previousPreviewOrderData === previewOrderData) {
        return
      }
      this.isPreviewOrderLoading = true
      this.previousPreviewOrderData = previewOrderData
      try {
        this.previewOrder = await billwerkApi.getPreviewOrder({ cart: this.cart, customer: this.customer })
        this.$emit('previewOrder', this.previewOrder)
      } catch (error) {
        Sentry.captureException(error)
      } finally {
        await new Promise((resolve) => setTimeout(resolve, 500))
        this.isPreviewOrderLoading = false
      }
    },
    updated () {
      this.fetchPreviewOrder()
      this.$emit('cart', this.cart)
      this.$emit('customer', this.customer)
    }
  }
}
</script>

<style>
.v-input--radio-group__input {
  gap: 1rem;
}
</style>
