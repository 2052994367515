<template>
  <v-text-field
    ref="field"
    v-model="model"
    class="verify-check-text-field"
    v-bind="$attrs"
    :style="cssProps"
    :error-messages="errors"
    :disabled="isLoading"
    @blur="handleBlur"
    v-on="$listeners"
    @focus="handleFocus"
    @keyup.enter="validate"
  >
    <template
      v-slot:append
    >
      <v-icon
        v-if="model.length > 0 && isInvalid && !isLoading"
        class="mr-2 pointer"
        color="error"
        @click="$emit('clear')"
      >
        mdi-close
      </v-icon>
      <v-icon
        v-else-if="model.length > 0 && isValid"
        class="mr-2"
        color="success"
      >
        mdi-check
      </v-icon>
      <v-btn
        text
        :loading="isLoading"
        :disabled="model.length === 0 || isLoading || !isDirty"
        :class="{ 'hovered-btn': isHovered, 'focused-btn': isFocused, 'error-btn': errors.length > 0 }"
        @click="validate"
      >
        {{ validateButtonText }}
      </v-btn>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    },
    isValid: {
      type: Boolean,
      default: false
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    validateButtonText: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      model: '',
      oldModel: '',
      isHovered: false,
      isFocused: false
    }
  },

  computed: {
    isDirty () {
      return this.oldModel !== this.model
    },
    cssProps () {
      return {
        '--primary-color': this.$vuetify.theme.themes.light.primary
      }
    }
  },

  watch: {
    value (value) {
      this.model = value
    },
    model (value) {
      this.$emit('input', value)
    }
  },

  mounted () {
    if (this.value) {
      this.model = this.value
    }

    if (this.validateButtonText) {
      this.$nextTick(() => {
        const input = this.$el.querySelector('.v-input__slot')
        if (input) {
          input.addEventListener('mouseenter', this.handleMouseEnter)
          input.addEventListener('mouseleave', this.handleMouseLeave)
        }
      })
    }
  },

  beforeDestroy () {
    if (this.validateButtonText) {
      const input = this.$el.querySelector('.v-input__slot')
      if (input) {
        input.removeEventListener('mouseenter', this.handleMouseEnter)
        input.removeEventListener('mouseleave', this.handleMouseLeave)
      }
    }
  },

  methods: {
    handleBlur () {
      this.isFocused = false
      this.$emit('blur')
    },

    handleFocus () {
      this.isFocused = true
    },
    handleMouseEnter () {
      this.isHovered = true
    },
    handleMouseLeave () {
      this.isHovered = false
    },

    async validate () {
      if (this.isDirty) {
        this.oldModel = this.model
        this.$emit('validate', this.model)
      }
    }
  }
}
</script>

<style>
.verify-check-text-field .v-input__slot {
  padding-right: 0 !important;
}
.verify-check-text-field .v-input__append-inner {
  margin: 0 !important;
}

.verify-check-text-field .v-btn {
  height: 100% !important;
  padding: 20px 10px !important;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.37);
}

.verify-check-text-field .v-btn.hovered-btn {
  border-left: 1px solid rgba(0, 0, 0, 0.87);
}
.verify-check-text-field .v-btn.focused-btn {
  border-left: 2px solid var(--primary-color)
}
.verify-check-text-field .v-btn.error-btn {
  border-left: 2px solid red
}

</style>
